/* color palette:
green
104, 145, 26
light
212, 217, 190
dark
48, 51, 32
light blue
144, 170, 184 */
* {
  text-decoration: none;
}
html,
body {
  height: 100%;
  width: 100%;
}
#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#navbar img {
  width: 10vw;
  padding: 5px;
  height: auto;
  object-fit: contain;
}
.dropdown {
  font-size: 2vh;
  display: flex;
  flex-direction: column;
  padding: 2vh;
}
.tile {
  background-image: url(./images/tile.jpg);
  background-repeat: repeat;
  background-size: contain;
  height: 100%;
  min-height: 100vh;
}
.main {
  background-image: url(./images/pasture_top.jpg);
  background-repeat: no-repeat;
  background-size: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  display: grid;
  grid-template-columns: 40vw 40vw;
  gap: 10vw;
  margin: 3%;
  width: 94%;
}
.description {
  background-color: rgba(144, 170, 184, 0.8);
  padding: 5%;
  margin-top: 7vh;
  height: 35vh;
  overflow: hidden;
}
.description h2 {
  padding: 0;
  margin: 0;
  margin-bottom: 2%;
}
.description p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
#slogan {
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0% 5%;
  font-weight: bold;
  width: 30vw;
}
#menu {
  padding: 2vw;
  overflow: visible;
}
.menuicon {
  font-size: 4vw;
  margin: 2vw;
}
.menuicon:hover {
  color: rgb(48, 51, 32);
  cursor: pointer;
}
.privacy {
  width: 75vw;
  background-color: rgba(144, 170, 184, 0.8);
  align-self: center;
  padding: 1%;
}
.profile {
  width: 100%;
  min-height: 100vh;
  align-self: center;
  display: grid;
  grid-template:
    "base base"
    "own interest"
    "foot foot";
}
#base {
  grid-area: base;
}
#interest {
  grid-area: interest;
}
#own {
  grid-area: own;
}
#foot {
  grid-area: foot;
}
.menudropdown {
  background-color: rgb(144, 170, 184);
  min-width: 100px;
  min-height: 100px;
  position: absolute;
  right: 1vw;
  display: flex;
  flex-direction: column;
}
.menudropdown div {
  display: flex;
  flex-direction: column;
}
.register {
  height: 80vh;
  display: flex;
  justify-content: space-around;
}
.formbox {
  /* min-width: 40vw; */
  background-color: rgba(144, 170, 184, 0.8);
  margin: 2.5vw;
  padding: 2vw;
  height: fit-content;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.inputfield {
  width: 75%;
  margin: 1vw;
}
.grid {
  display: grid;
  grid-template-columns: auto auto auto;
  padding-bottom: 5%;
}
.checkbox {
  display: flex;
  min-width: fit-content;
  justify-content: space-between;
  padding-right: 2vw;
}
@media (max-width: 999px) {
  .container {
    grid-template-columns: 85vw;
  }
  .description {
    width: 85vw;
    height: auto;
  }
  .menuicon {
    font-size: 7vh;
  }
  .profile {
    grid-template:
      "base"
      "own"
      "interest"
      "foot";
  }
  .formbox {
    width: 90vw;
  }
}
@media (max-height: 900px) {
  .description p {
    -webkit-line-clamp: 9;
  }
}
@media (max-height: 700px) {
  .description p {
    -webkit-line-clamp: 7;
  }
}
@media (max-height: 500px) {
  .description p {
    -webkit-line-clamp: 4;
  }
}
@media (max-height: 300px) {
  .description p {
    -webkit-line-clamp: 2;
  }
}
