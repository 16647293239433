.graph {
  height: 35vh;
  width: 100%;
  padding: 5%;
  margin-top: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: rgba(144, 170, 184, 0.8);
  position: relative;
}
.value,
.date {
  display: none;
  position: absolute;
  background-color: rgba(212, 217, 190, 0.8);
  color: rgba(48, 51, 32, 1);
  font-weight: bold;
  z-index: 3;
  padding: 2px;
}
.column:hover span {
  display: unset;
}
.column:hover img {
  width: 130%;
}
.column {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 3%;
  position: relative;
  margin-left: -1vw;
  align-items: center;
}
.column-value {
  display: flex;
  width: 100%;
  overflow: visible;
  white-space: nowrap;
  position: absolute;
  bottom: 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
}
.column-label {
  overflow: visible;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.value {
  white-space: nowrap;
}
.HQdot,
.dot {
  width: 100%;
  bottom: 0px;
  left: 0;
}
.legend-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.relative-legend-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.relative {
  position: relative;
}
.underline {
  border-bottom: 2px dotted rgb(41, 58, 10);
  width: 100%;
}
.median {
  position: absolute;
  font-weight: bold;
  display: flex;
  align-items: baseline;
}
min {
  color: rgb(199, 23, 23);
}

max {
  color: rgba(104, 145, 26, 1);
}
.legend {
  bottom: 0;
  left: 0;
  position: absolute;
  font-weight: bold;
}
.graph-container h2 {
  margin: 0;
  padding: 0;
  height: 0;
}
@media (max-width: 999px) {
  .HQdot,
  .dot {
    width: 150%;
    bottom: 0px;
    left: 0;
  }
  .column:hover img {
    width: 200%;
  }
}
